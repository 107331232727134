'use client'

import Cookie from 'js-cookie'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'

import { isDev } from '@/lib/utils'
import { siteConfig } from '@/config'

export function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const consentCookie = Cookie.get(siteConfig.cookieKeys.consent)
    // if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') {
    //   Cookie.remove(siteConfig.cookieKeys.consent)
    // }
    if (!consentCookie) {
      setShowBanner(true)
    }
  }, [])

  const handleAccept = useCallback(() => {
    const expires = isDev(1, 182)
    setShowBanner(false)
    Cookie.set(siteConfig.cookieKeys.consent, 'accepted', { expires })
  }, [])

  // const handleReject = useCallback(() => {
  //   const expires = isDev(1, 14)
  //   setShowBanner(false)
  //   Cookie.set(siteConfig.cookieKeys.consent, 'rejected', { expires })
  // }, [])

  if (!showBanner) {
    return (<></>)
  }

  return (
    <div className="bg-white border border-cyan-400/80 border-2 bottom-6 fixed flex flex-col md:flex-row items-center justify-between left-6 px-3 md:px-5 py-4 md:py-6 right-6 rounded-md md:rounded-lg shadow-2xl md:shadow-xl text-xs md:text-sm">
      <p className="mb-5 md:mb-0 text-center md:text-left leading-6">
        {`This website uses cookies. By continuing, you agree to our `}
        <Link className="text-rose-600 hover:underline" href="/privacy">Privacy Policy</Link>
        {`.`}
      </p>
      <div className="flex gap-x-2">
        <button className="btn btn-primary btn-sm" onClick={handleAccept}>
          {`Continue`}
        </button>
        {/* <button className="btn btn-default btn-sm" onClick={handleReject}>
          {`Reject`}
        </button> */}
      </div>
    </div>
  )
}
